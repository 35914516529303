import React from 'react';
import {BoxBackground, CustomHeader2, CustomP, CustomP2White, Section} from "../components/Headers";
import {Typography} from "antd"
import {Link} from "react-router-dom"
import {useIsMobile} from "../components/LayoutWrapper"

const {Title} = Typography

const CareersHeader: React.FC = () => {
    const isMobile = useIsMobile()
    return (

        <BoxBackground

            style={{
                height: 'auto',
                marginBottom: '10vh'
            }}

        >
            <Title
                style={{
                    marginTop: '100px',
                    // width: '40%',
                    textAlign: 'center'
                }}
            >
                Work with us
            </Title>
            <CustomP

                style={{
                    marginLeft: isMobile ? undefined : '15vh',
                    marginRight: isMobile ? undefined : '15vh',
                    textAlign: 'center'
                }}

            >
                Join spaik to shape the future of AI and sports
            </CustomP>
        </BoxBackground>

    );
}

const CareersCall: React.FC = () => {
    const isMobile = useIsMobile()
    return (

        <Section

        >
            <div
                style={{
                    marginLeft: isMobile ? '2vh' : '5vh',
                    marginRight: isMobile ? '2vh' : '10vh'

                }}

            >
                <CustomHeader2>
                    Careers
                </CustomHeader2>
                <CustomP2White>
                    spaik seeks passionate and talented individuals eager to make an impact. Be part of our dynamic team
                    driving innovation in AI and sports.
                    To explore opportunities and start a conversation email us.
                    <br/>
                    <br/>
                    {/*<MailOutlined />*/}
                    <Link
                        to={"mailto:xavier@boschmonart.com"}
                        style={{color: 'white', textDecoration: 'underline'}}
                    >
                        jobs@spaik.app
                    </Link>
                </CustomP2White>
            </div>
        </Section>

    )
}

const Careers: React.FC = () => {
    return (
        <div>
            <CareersHeader/>
            <CareersCall/>
        </div>
    );
};

export default Careers;