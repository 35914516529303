import React from 'react';
import {
    BoxBackground,
    CustomHeader,
    CustomHeader2,
    CustomP,
    CustomP2,
    CustomP2White,
    Section,
    SectionGrey
} from "../components/Headers";
import {Col, Row, Typography} from "antd"
import {useIsMobile} from "../components/LayoutWrapper"
import {colorPrimary} from "../global"

const {Title} = Typography

const AboutUsHeader: React.FC = () => {
    const isMobile = useIsMobile()

    return (
        <BoxBackground

            style={{
                height: 'auto',
                marginBottom: '10vh'
            }}

        >
            <Title
                style={{
                    marginTop: '100px',
                    // width: '40%',
                    textAlign: 'center'
                }}
            >
                About us
            </Title>
            <CustomP

                style={{
                    marginLeft: isMobile? undefined:'10vh',
                    marginRight: isMobile? undefined:'10vh',
                    textAlign: 'center'
                }}

            >
                Discover our values and meet the founders who are leading spaik into the future.
            </CustomP>
        </BoxBackground>
    );
}

const ValuesSection: React.FC = () => {
    const companyValues = [
        {
            title: "Ownership",
            description: "We take proactivity very seriously. Don’t wait for someone to tell you, act and own the outcome, whether it is good or bad. Take decisions and be responsible for them."
        },
        {
            title: "Transparency",
            description: "Everyone at spaik knows all the numbers. Also the financial ones. Transparency goes hand by hand with ownership. Having a clear and transparent roadmap helps the team to focus towards a common objective."
        },
        {
            title: "Try, fail, try again",
            description: "Don’t be afraid to take risks. Do, if you fail, prevail, do it again, and do it better while you learn along the way."
        },
        {
            title: "Lead by example",
            description: "We expect everyone at spaik to lead. In this leadership, be honest, be proactive, and be professional."
        },
        {
            title: "Team players",
            description: "We believe in our mates. Help each other when needed and value empathy. We are a team."
        },
        {
            title: "Flexible mentality",
            description: "We avoid monolithic mentality. We love trying new things, incorporating new processes, and don’t be afraid to change our mind."
        },
        {
            title: "Always learning",
            description: "We are always learners. We love to learn new tools, languages, or processes."
        }
    ];
    const isMobile = useIsMobile()
    let span = 6
    if (isMobile) {
        span = 10
    }
    return (
        <Section>
            <CustomHeader style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                Our values
            </CustomHeader>
            <Row>
            {companyValues.map((val, index) => (

                            <Col span={span} offset={1}>
                                <CustomHeader2>{val.title}</CustomHeader2>
                                <CustomP2White

                                    style={{
                                    //     marginLeft: 0,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        }}


                                >{val.description}</CustomP2White>
                            </Col>
                        ))}



                </Row>
        </Section>
    )
}

const FoundersSection: React.FC = () => {
    const founders = [
        {
            name: "Xavier Boschmonart",
            role: "CEO",
            image: 'xavi.jpg',
            description: "Xavi holds a Mathematician degree and a Master’s in Advanced Mathematics. He has been working as a Data Scientist and Full Stack Engineer for over 10 years in Big Tech Companies."
        }]
    const isMobile = useIsMobile()
    let span = 6
    if (isMobile) {
    span = 23
    }
    return (
        <SectionGrey

        >
            <CustomHeader style={{width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                Our founders
            </CustomHeader>
            <Row >
            {founders.map((val, index) => (

                            <Col  span={span} offset={1}>
                                <div
                                style={{
                                          display: 'flex',

                                    justifyContent: isMobile? 'center': 'left',
                                    width: '100%',
                                }}
                                >
                                <img src={require(`../icons/${val.image}`)}
                                     style={{
                                         height: '14vh',
                                             border: `4px solid darkgrey`,

                                     }}
                                />
                                    </div>
                                <CustomHeader2>{val.name}</CustomHeader2>
                                <CustomP2
                                    style={{
                                        marginBottom: '0vh'
                                    }}
                                >{val.role}</CustomP2>

                                <CustomP2
                                    style={{
                                        marginTop: '0vh'
                                    }}
                                >{val.description}</CustomP2>
                            </Col>
                        ))}



                 </Row>
        </SectionGrey>
    )
}


const AboutUs: React.FC = () => {


    return (
        <div>
            <AboutUsHeader/>
            <ValuesSection/>
            <FoundersSection/>
        </div>
    );
};

export default AboutUs;