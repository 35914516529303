import React, {useEffect, useRef} from 'react';
import {BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip} from 'chart.js';
import {useIsMobile} from "./LayoutWrapper";
import {colorPrimary} from "../global";

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const AnimatedPrettyPlot: React.FC = () => {
    const isMobile = useIsMobile();
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstanceRef = useRef<Chart | null>(null);

    useEffect(() => {
        const ctx = chartRef.current?.getContext('2d');
        if (ctx) {
            chartInstanceRef.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['Market penetration', 'Cost efficiency', 'Productivity boost', 'Customer satisfaction', 'Innovation rate'],
                    datasets: [
                        {
                            label: 'Without spaik',
                            data: [12, 15, 10, 20, 9],
                            backgroundColor: colorPrimary
                        },
                        {
                            label: 'With spaik',
                            data: [20, 25, 14, 29, 16],
                            backgroundColor: 'darkgreen'
                        }
                    ]
                },
                options: {
                    indexAxis: isMobile ? 'x' : 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 1000,
                        easing: 'easeOutBounce'
                    },
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                                padding: 20
                            }
                        },
                        title: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => `${context.dataset.label}: ${context.raw}%`
                            }
                        }
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            ticks: isMobile ? {} : {
                                callback: (value) => `${value}%`
                            },
                            grid: {
                                display: false
                            }
                        },
                        y: {
                            beginAtZero: true,
                            ticks: isMobile ? {
                                callback: (value) => `${value}%`
                            } : {},
                            grid: {
                                display: false
                            }
                        }
                    },
                    layout: {
                        padding: {
                            top: 20,
                            bottom: 20,
                            left: 20,
                            right: 20
                        }
                    }
                }
            });
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && chartInstanceRef.current) {
                    chartInstanceRef.current.reset();
                    chartInstanceRef.current.update();
                }
            },
            {threshold: 0.1}
        );

        if (chartRef.current) {
            observer.observe(chartRef.current);
        }

        return () => {
            observer.disconnect();
            chartInstanceRef.current?.destroy();
        };
    }, [isMobile]);

    return (
        <div style={{
            backgroundColor: 'white',
            // padding: isMobile ? '5px' : '0px',
            // width: '100%',
            height: '37vh'
        }}>
            <canvas ref={chartRef}/>
        </div>
    );
};

export default AnimatedPrettyPlot;