import React from 'react';
import {Layout, Typography} from 'antd';
import ReactMarkdown from 'react-markdown';

const {Title} = Typography;

const {Content, Footer} = Layout;

const PrivacyPolicy = () => {
    const markdown = `
## **Privacy Policy**

### **Introduction**

We are committed to protecting
the privacy of our users and clients. This Privacy Policy outlines how we collect, use, and protect your
personal data in compliance with applicable data protection laws in Spain.
### **Data Collected on Our Website spaik.app**

#### **1\\. Data Provided When Completing Forms on Our Website**

When you visit our Site, you may provide us with personal data by completing forms under headings such
as “Contact Us” or “Book a Demo”. Each form specifies the purposes for collecting this data, the type of
data collected, and whether the data is compulsory or optional for processing your request. Your data is
used exclusively by spaik Hub to process your request. Your data is stored on secure servers located in
Spain in accordance with confidentiality and security measures implemented by our hosting provider. Data
is not shared with third parties unless we have your permission or are required to do so by law or court
order.

**2\\. Data Collected via Cookies**

Our website uses different types of cookies on your computer: first-party cookies (cookies containing
the domain name “spaik.app”) and third-party cookies (required for optimal site operation and sharing
content on social networks). Upon your first visit and/or after your consent to our cookies has expired,
a banner will inform you about cookie usage, inviting you to accept or configure settings to save or use
the cookies, globally or for each category. Cookies are only saved after you have accepted them or if
you continue navigating on our website.

The cookies collect navigation data, such as the browser used, pathway to our site, pages viewed,
actions taken, time spent on the site, and frequency of return. These navigation data contain no
information that could be used to directly or indirectly identify an individual. Our cookies also
collect pseudonymized data, including:

- The IP address, shortened to the last few bytes, solely for identifying the geographical location of
the user (no more specific than the town/city of connection).
- An identifier attributed to the device used to connect to our website.

These cookies are used for the following purposes:

- To improve the performance of our services: cookies help us analyze traffic on our site and make
statistical reports to improve efficiency.
- To customize your navigation: cookies store information about your browsing preferences to optimize
your visit, including the choice of language and the country of origin. This data does not contain
information that could directly or indirectly identify an individual, except for the IP address which is
collected solely for the geographic location of the user.

### **Your Rights**

In accordance with applicable legislation, you have the right to access, modify, object to, erase, or
request the portability of your personal data. You also have the right to obtain a restriction of the
processing or to give instructions regarding the conservation, deletion, storage, and communication of
your personal data after your death. To exercise these rights, please send your request by email
to info@spaik.app. All requests must be accompanied by a valid identity document.

### **Copyrights**

The content and works on this Site are subject to Spanish copyright law. Copying, processing,
distribution, and any kind of exploitation beyond the limits of copyright require the written consent of
the respective author or creator. Downloads and copies of these pages are only permitted for private and
non-commercial use. Insofar as the content on this Site was not created by an operator, the copyrights
of third parties are respected. Content from third parties is marked as such. If you become aware of any
copyright infringement, please notify us accordingly. Upon becoming aware of legal violations, we will
immediately remove such content.

### **Contact**

For more information about our data protection policies, please contact our data protection officer by
email at info@spaik.app.

**spaik Hub** **Spain**
`

    return (
        <Content className="content"
                 style={{
                     display: 'flex',
                     flexDirection: 'column',
                     // justifyContent: 'center',
                     alignItems: 'center',
                     paddingTop: ' 5vh',
                     paddingBottom: '10vh',
                     backgroundColor: 'white',
                     // height: '60vh'
                 }}>
            <div
                style={{
                    width : '60vw',
                    textAlign: 'justify',
                }}
            >
                <ReactMarkdown>
                    {markdown}
                </ReactMarkdown>
            </div>
        </Content>
    );
};

export default PrivacyPolicy