import {Button, Col, ConfigProvider, Drawer, Layout, Menu, MenuProps, Row} from 'antd';
import {LinkedinOutlined, MenuOutlined} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import './LayoutWrapper.css';
import {ReactComponent as Logo} from '../icons/base_logo.svg'
import {ButtonDemo} from "./ButtonDemo"
import {HappyProvider} from '@ant-design/happy-work-theme';
import {colorPrimary, fontSizeSmall} from "../global"

const {Header, Footer} = Layout;

interface LayoutWrapperProps {
    component: React.ComponentType
    is_header?: boolean;
    // title: string;
}

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768)
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    return isMobile
}


function getLinkItem(path: string, clickable = true) {

    const link = clickable ?
        <Link to={`/${formatPath(path)}`}
              style={{
                  fontSize: fontSizeSmall,
                  color: 'grey'
              }}
        >{path}</Link> :
        <div
            style={{
                fontSize: fontSizeSmall,
                color: 'grey'
            }}>{path}</div>

    return {
        label: link,
        key: path
    };
}

function formatPath(s: string) {
    return s.toLowerCase().replace(' ', '-');
}

const LayoutWrapper: React.FC<LayoutWrapperProps> = (
        {
            component: WrappedComponent,
            is_header = false
        }) => {

        const isMobile = useIsMobile()

        const location = useLocation();
        let title = location.pathname.substring(1);
        if (title === '') {
            title = 'homepage';
        }
        console.log(title, 'title');
        const title_key = title.charAt(0).toUpperCase() + title.slice(1);
        const items: MenuProps['items'] = [

            // {
            //     label: <Link to="/solutions">Alumni</Link>,
            //     key: 'Solutions',
            // },
            // {
            //     ...getLinkItem("Resources", false),
            //     children: [
            //         getLinkItem("Blog"),
            //         getLinkItem("Demo")
            //     ]
            //
            // },

            // getLinkItem('Pricing'),

            {
                ...getLinkItem('Company', false),
                children: [
                    getLinkItem("About us"),
                    getLinkItem("Careers")
                ]

            }

        ]

        // const items: MenuProps['items'] = items_

        const itemsMobile: MenuProps['items'] = [
            {
                icon: <MenuOutlined/>,
                key: 'menu-outline',
                children: items
            }
        ]
        const [open, setOpen] = useState(false)

        const toggleDrawer = () => setOpen(!open)
        return (
            <HappyProvider>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: colorPrimary,
                            borderRadius: 10,
                            padding: 20,
                            fontFamily: 'Montserrat',
                            fontWeightStrong: 1,
                            fontSize: 20,
                            // fontSizeHeading1: 50,
                            fontSizeHeading1: isMobile ? 40 : 55,

                            fontSizeHeading2: isMobile ? 25 : 33,
                            fontSizeHeading3: isMobile ? 22 : 29


                        }
                    }}
                >
                    <Layout className="layout content" style={{
                        backgroundColor: 'white',
                        // backgroundImage: `url(${BottomLeftBackround}), url(${TopRightBackground})`,
                        // backgroundPosition: '-5vw 60vh, 80vw -8vh',
                        // backgroundRepeat: 'no-repeat, no-repeat',
                        // height: '100vh',
                        width: '99vw'
                        // animation: 'fadeInAnimation 1s linear',
                    }}>
                        <Header style={{
                            backgroundColor: 'white',
                            borderBottom: '1px solid lightgreen',
                            height: '10vh',
                            paddingBottom: '1px',
                            display: 'flex',
                            // justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Row style={{width: '100%'}}>
                                <Col
                                    span={3}

                                    style={{
                                        height: '110%',
                                        width: '100%',
                                    }}
                                >
                                    <Link to="/">
                                        <Logo style={{
                                            height: '90%',

                                        }}
                                        />
                                    </Link>
                                </Col>
                                <Col
                                    span={isMobile ? 22 : 12}
                                    // offset={1}
                                    style={{
                                        // marginTop: isMobile? '30px': '10px',
                                        display: isMobile ? 'flex' : 'flex',
                                        justifyContent: isMobile ? 'end' : 'start',
                                        alignContent: isMobile ? 'center' : 'start',
                                        height: '100%',
                                        width: '100%',
                                        // alignItems: isMobile ? 'start' : undefined,

                                        // width: '90%'
                                    }}
                                >
                                    {isMobile ?
                                        // <div>
                                        <div>

                                            <Button
                                                type={'text'}
                                                style={{
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignContent: 'center'

                                                }}


                                                onClick={toggleDrawer} icon={<MenuOutlined/>}/>
                                            <Drawer open={open} onClose={toggleDrawer} placement="top"
                                                    size={'large'}
                                            >
                                                <Menu mode="inline" items={items}
                                                      onClick={toggleDrawer}/>
                                            </Drawer>
                                        </div>
                                        :
                                        <Menu
                                            // selectedKeys={[title_key]}
                                            mode={"horizontal"}

                                            items={items}
                                            style={{
                                                width: '100%',
                                                borderBottom: 'transparent',
                                                display: 'flex',
                                                // justifyContent: 'end',
                                                alignItems: 'center'
                                                // alignContent: 'flex-end',
                                                // fontSize: fontSizeSmall,
                                                // color: 'grey'
                                            }}


                                        />}
                                </Col>
                                {isMobile ? null :
                                    <Col span={9}
                                         style={{
                                             display: 'flex',
                                             justifyContent: 'end',
                                             alignItems: 'center',
                                             height: '100%'
                                             // marginRight: '-500px'
                                         }}

                                    >
                                        {isMobile ? null :
                                            <ButtonDemo
                                                style={{

                                                    width: '178px',
                                                    height: '50px',
                                                    fontSize: '15px'


                                                }}

                                            />
                                        }
                                    </Col>
                                }


                            </Row>


                        </Header>
                        <WrappedComponent/>
                        <Footer style={{
                            backgroundColor: 'white',
                            borderTop: '1px solid lightgreen',
                            height: '15vh',
                            display: 'flex',
                            // justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            width: '100%'
                        }}>
                            <Col
                                span={8}
                                style={{
                                    display: 'flex',
                                    // justifyContent: 'flex-start',
                                    // justifyContent: 'center',
                                    flexDirection: 'column'
                                }}

                            >
                                <p
                                    style={{
                                        fontSize: fontSizeSmall
                                    }}

                                >
                                    <strong>spaik</strong> <br/>
                                    c/ sant robert, 34, Calella, Spain <br/>
                                    xavier@boschmonart.com

                                </p>
                                <Link
                                    to={'https://www.linkedin.com/company/laiahub/'}
                                    target="_blank"

                                >
                                    <LinkedinOutlined/>
                                </Link>
                            </Col>
                            <Col
                                span={8}
                                style={{
                                    display: 'flex'
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                }}
                            >
                            </Col>
                            <Col
                                span={8}
                                style={{
                                    display: 'flex',
                                    // justifyContent: 'flex-start',
                                    alignItems: 'start',
                                    flexDirection: 'column'
                                }}
                            >
                                {/*<Button*/}
                                {/*    type='text'*/}
                                {/*    style={{*/}
                                {/*        // marginTop: '10px',*/}

                                {/*        fontSize: fontSizeSmall,*/}

                                {/*    }}>*/}
                                {/*    Terms*/}
                                {/*</Button>*/}
                                <Link to={'/privacy-policy'}>
                                    <Button
                                        type="text"
                                        style={{
                                            // marginTop: '10px',
                                            fontSize: fontSizeSmall
                                        }}

                                    >
                                        Privacy policy
                                    </Button>
                                </Link>

                            </Col>
                        </Footer>
                    </Layout>
                </ConfigProvider>
            </HappyProvider>
        );
    }
;

export default LayoutWrapper;